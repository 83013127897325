import React from 'react'

const PrivacyPolicies = () => {
    return (
        <a href="https://www.iubenda.com/privacy-policy/51399112"
        className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
        title="Política de Privacidade ">
            Política de Privacidade
        </a>
        
    )
}

export default PrivacyPolicies